
import LayoutElement from './LayoutElement';
import PropTypes from 'myassays-global/PropTypes';

export default class ResizableElement extends LayoutElement {
    static get propTypes() {
        return {
            ...super.propTypes,
            id: PropTypes.string.required,
            initialBasis: PropTypes.dimension,
            initialGrow: PropTypes.number,
        }
    }

    constructor() {
        super();
    }

    get initialState() {
        const size = this._props.initialBasis === undefined ? 'unset' : this._props.initialBasis;
        return {
            ...super.initialState,
            size,
            forceFluid: false,
            isResizing: false,
            showing: this._props.initiallyShowing,
        }
    }

    stateChangedCallback(evt) {
        super.stateChangedCallback(evt);
        const { hasChanged, attributes } = evt.data;

        hasChanged('isResizing', truth => {
            this.classList.toggle('resizing', truth);
        });

        if (hasChanged('size') || hasChanged('forceFluid')) {
            this.updateView();
        }
    }

    get grow() {
        const {size, forceFluid} = this.state;
        const {initialGrow} = this.props;
        const grow = size !== 'unset' ? 'unset' : initialGrow !== undefined ? initialGrow : '1';
        return (grow === 'unset' && forceFluid) ? '1' : grow;
    }

    get basis() {
        const { size, forceFluid } = this.state;
        return forceFluid ? 'unset' : size;
    }

    get templateData() {
        return {
            ...super.templateData,
            grow: this.grow,
            basis: this.basis,
            width: this.isSideways === null ? '100%' : (this.isSideways ? 'unset' : '100%'),
            height: this.isSideways === null ? '100%' : (!this.isSideways ? 'unset' : '100%'),
        }
    }

    updateView() {
        super.updateView();
        if (this.isVisible) {
            if (this._saveMinWidth !== undefined) {
                this.style.minWidth = this._saveMinWidth;
                delete this._saveMinWidth;
            }
            this.style.flexBasis = this.basis;
            this.style.flexGrow = this.grow;
        } else {
            this.style.flexBasis = '0';
            this.style.flexGrow = '0';
            if (this.style.minWidth) {
                this._saveMinWidth = this.style.minWidth;
                this.style.minWidth = '0';
            }
        }
    }
}
