
function cloneValue(value) {
    if (value instanceof Model) {
        return value.attributes;
    } else if (value instanceof Array) {
        return value.map(cloneValue);
    } else if (value instanceof Object) {
        const returnValue = {};
        Object.keys(value).forEach(key => {
            returnValue[key] = cloneValue(value[key]);
        });
        return returnValue;
    } else {
        return value;
    }
}

export default class Model extends EventTarget {
    constructor(initialAttributes = {}) {
        super();
        this._previousAttributes = {};
        this._attributes = {...this.constructor.defaults, ...initialAttributes};
    }

    set(newAttributes, noTrigger = false) {
        this._previousAttributes = {...this._attributes};
        const previousAttributes = {...this._attributes};
        Object.keys(newAttributes).forEach(key => {
            const newAttribute = newAttributes[key];
            if (this._attributes[key] instanceof Model) {
                if (newAttribute instanceof Model) {
                    this._attributes[key] = newAttribute;
                } else {
                    this._attributes[key].set(newAttribute);
                }
            } else {
                this._attributes[key] = newAttribute;
            }
        });

        if (!noTrigger) {
            const evt = new Event('change');
            const attributes = this._attributes;
            evt.data = {
                attributes,
                previousAttributes,
                hasChanged: (attributeName, handler) => {
                    const prevValue = previousAttributes[attributeName];
                    const newValue = attributes[attributeName];
                    const changed = newValue !== prevValue;
                    changed && handler && handler(newValue, prevValue);
                    return changed;
                },
            }
            this.dispatchEvent(evt);
        }
        noTrigger || Object.keys(this._attributes).forEach(key => {
            const previousValue = previousAttributes[key];
            const newValue = this._attributes[key];
            if (previousValue !== newValue) {
                const evt = new Event(`change:${key}`);
                evt.data = {
                    previousValue: cloneValue(previousValue),
                    newValue: cloneValue(newValue),
                }
                this.dispatchEvent(evt);
            }
        });
    }

    getExact(attributeName) {
        return this._attributes[attributeName];
    }

    get(attributeName) {
        const value = this._attributes[attributeName];
        if (value instanceof Model) {
            return value;
        } else {
            return cloneValue(value);
        }
    }

    reset() {
        this._previousAttributes = {};
        this._attributes = {...this.constructor.defaults};
    }

    hasChanged(attributeName, handler) {
        const prevValue = this._previousAttributes[attributeName];
        const newValue = this._attributes[attributeName];
        const changed = newValue !== prevValue;
        changed && handler && handler(newValue, prevValue);
        return changed;
    }

    set attributes(value) {
        this._attributes = {...value};
    }

    get attributes() {
        return cloneValue(this._attributes);
    }

    get previousAttributes() {
        return cloneValue(this._previousAttributes);
    }

    static defaults = {};
}
