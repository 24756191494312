import {Model} from 'myassays-global';

export default class Animator {
    static DELAY = 300;
    static phases = {
        INITIALISE: 'initialise',
        START: 'start',
        END: 'end',
        CANCELLED: 'cancelled',
        IDLE: 'idle',
    }

    static create(delay) {
        return new Animator(delay);
    }

    onInitialise(callBack) {
        this._initialiseCallBack = callBack;
        return this;
    }

    onStart(callBack) {
        this._startCallBack = callBack;
        return this;
    }

    onEnd(callBack) {
        this._endCallBack = callBack;
        return this;
    }

    onIdle(callBack) {
        this._idleCallBack = callBack;
        return this;
    }

    onCancel(callBack) {
        this._cancelCallBack = callBack;
        return this;
    }

    constructor(delay) {
        const { phases, DELAY } = Animator;
        this._delay = delay === undefined ? DELAY : delay;
        this._phase = phases.IDLE;
        this._timerId = undefined;
        return this;
    }

    begin() {
        const { phases } = Animator;
        this.cancel();
        this._phase = phases.INITIALISE;
        this._timerId = setTimeout(() => {
            this._phase = phases.START;
            this._timerId = setTimeout(() => {
                this._phase = phases.END;
                this._timerId = setTimeout(() => {
                    this._phase = phases.IDLE;
                    delete this._timerId;
                    this._idleCallBack(this);
                }, 1);
                this._endCallBack(this);
            }, this._delay);
            this._startCallBack(this);
        }, 1);
        this._initialiseCallBack(this);
        return this;
    }

    cancel() {
        const { phases } = Animator;
        if (this._timerId !== undefined) {
            clearTimeout(this._timerId);
            delete this._timerId;
            this._phase = phases.CANCELLED;
            this._cancelCallBack(this);
        }
        return this;
    }

    get delay() {
        return this._delay;
    }

    get phase() {
        return this._phase;
    }
}
