
import Layout from './src/Layout';
import Group from './src/Group';
import Pane from './src/Pane';
import MinimisedPaneList from './src/MinimisedPaneList';
import Splitter from './src/Splitter';
import 'construct-style-sheets-polyfill';

customElements.define('mp-layout', Layout);
customElements.define('mp-group', Group);
customElements.define('mp-pane', Pane);
customElements.define('mp-minimised-pane-list', MinimisedPaneList);
customElements.define('mp-splitter', Splitter);

if (window.MyAssaysPanes !== undefined) {
    if (!(window.MyAssaysPanes instanceof Object)) {
        throw new Error('Could not create "window.MyAssaysPanes" namespace.');
    }
} else {
    window.MyAssaysPanes = {};
}
window.MyAssaysPanes = {
    ...window.MyAssaysPanes,
    getPane: function (layoutId, paneId) {
        return document.getElementById(layoutId).getPaneById(paneId);
    },
    showPane: function (layoutId, paneId) {
        MyAssaysPanes.getPane(layoutId, paneId).show();
    },
    hidePane: function (layoutId, paneId) {
        MyAssaysPanes.getPane(layoutId, paneId).hide();
    },
    minimisePane: function (layoutId, paneId) {
        MyAssaysPanes.getPane(layoutId, paneId).minimise();
    },
    maximisePane: function (layoutId, paneId) {
        MyAssaysPanes.getPane(layoutId, paneId).maximise();
    },
    restorePane: function (layoutId, paneId) {
        MyAssaysPanes.getPane(layoutId, paneId).restore();
    },
    isMinimised: function (layoutId, paneId) {
        return MyAssaysPanes.getPane(layoutId, paneId).isMinimised;
    },
    isMaximised: function (layoutId, paneId) {
        return MyAssaysPanes.getPane(layoutId, paneId).isMaximised;
    },
}
