
export const directions = {
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal',
};

export const modes = {
    NORMAL: 'normal',
    MAXIMISED: 'maximised',
    MINIMISED: 'minimised',
};

export const events = {
    RE_FLOW: 'UI.reFlow',
};

export const animationDurationMilliseconds = 500

const constants = {
    directions,
    modes,
    events,
    animationDurationMilliseconds,
}

export default constants;
