

import { modes } from './constants';
import PropTypes from 'myassays-global/PropTypes';
import ResizableElement from './ResizableElement.js';

export default class Pane extends ResizableElement {
    static get propTypes() {
        return {
            ...super.propTypes,
            titleText: PropTypes.string.default('Untitled pane').observed,
            minimisedTitle: PropTypes.string.default('').observed,
            canMaximise: PropTypes.bool.default(true),
            canMinimise: PropTypes.bool.default(true),
            showTitleBar: PropTypes.bool.default(true).observed,
            initialMode: PropTypes.string.lookup(modes).default(modes.NORMAL),
            resizeUpdateInterval: PropTypes.number.default(0),
            initiallyShowing: PropTypes.bool.default(true),
            style: PropTypes.style.default(''),
            class: PropTypes.string.default(''),
            titleBarStyle: PropTypes.style.default(''),
            contentStyle: PropTypes.style.default(''),
        }
    }

    get initialState() {
        return {
            ...super.initialState,
            mode: this._props.initialMode,
            forceMinimised: false,
        }
    }

    constructor() {
        super();
        this.shadow.addEventListener('click', this.onClick);
        this.shadow.addEventListener('dblclick', this.onDoubleClick);
    }

    connectedCallback() {
        super.connectedCallback();
        if (this.props.resizeUpdateInterval !== 0) {
            this._resizeObserver = new ResizeObserver(() => {
                if (!this._preventReflow) {
                    this._preventReflow = true;
                    setTimeout(() => {
                        this.dispatchEvent(new CustomEvent('paneresize', {
                            bubbles: true,
                            detail: {
                                layoutId: this.layout.id,
                                paneId: this.id,
                            },
                        }));
                        this._preventReflow = false;
                    }, this.props.resizeUpdateInterval);
                }
            });
            this._resizeObserver.observe(this);
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this._resizeObserver && this._resizeObserver.disconnect();
    }

    onClick = evt => {
        switch (evt.target.className) {
            case 'titleBar minimise':
                evt.preventDefault();
                this.minimise();
                break;
            case 'titleBar maximise':
                evt.preventDefault();
                this.maximise();
                break;
            case 'titleBar restore':
                evt.preventDefault();
                this.restore();
                break;
        }
    }

    maximise() {
        this.layout.saveElementSizes();
        this.setState({mode:modes.MAXIMISED});
        this.layout.animateElements();
    }

    minimise() {
        this.layout.saveElementSizes();
        this.setState({mode: modes.MINIMISED});
        this.layout.animateElements();
    }

    restore() {
        this.layout.saveElementSizes();
        this.setState({mode:modes.NORMAL});
        this.layout.animateElements();
    }

    onDoubleClick = evt => {
        if (this.props.canMaximise && evt.target.className === 'titleBar title') {
            if (this.state.mode === modes.NORMAL) {
                this.maximise();
            } else if(this.state.mode === modes.MAXIMISED) {
                this.restore();
            }
            evt.preventDefault();
        }
    }

    stateChangedCallback(evt) {
        super.stateChangedCallback(evt);
        const { hasChanged, attributes } = evt.data;

        hasChanged('isRezising', truth => {
            const elem = this.shadow.querySelector('.content');
            elem && (elem.style.pointerEvents = truth ? 'none' : 'all');
        });

        if (hasChanged('forceMinimised') || hasChanged('mode') || hasChanged('showing')) {
            this.group.onMemberVisibilityChange(this);
            this.updateView();
        }

        hasChanged('mode', (value, previousValue) => {
            this.setPaneControls();
            this.layout.onPaneStateChange(this, value, previousValue);
        });
    }

    setPaneControls() {
        const value = this.state.mode;
        let elem = this.shadow.querySelector('.restore');
        elem && (elem.style.display = this.props.canMaximise && value === modes.MAXIMISED ? 'block' : 'none');
        elem = this.shadow.querySelector('.maximise');
        elem && (elem.style.display = this.props.canMaximise && value === modes.NORMAL ? 'block' : 'none');
    }

    get isVisible() {
        const { showing, mode, forceMinimised } = this.state;
        return showing && mode !== modes.MINIMISED && !forceMinimised;
    }

    get isMinimised() {
        return this.state.mode === modes.MINIMISED || this.state.forceMinimised;
    }

    get isMaximised() {
        return this.state.mode === modes.MAXIMISED;
    }

    propertyChangedCallback(attrName, oldVal, newVal) {
        let elem;
        switch (attrName) {
            case 'titleText':
                elem = this.shadow.querySelector('.title');
                elem && (elem.text = newVal);
                break;
            case 'minimisedTitle':
                // TODO: update minimised panes list
                break;
            case 'showTitleBar':
                elem = this.shadow.querySelector('.titleBarContainer');
                elem && (elem.style.display = newVal ? 'flex' : 'none');
                break;
        }
    }

    show() {
        this.layout.saveElementSizes();
        this.setState({
            showing: true,
            mode: modes.NORMAL,
            forceMinimised: false,
        });
        this.layout.onPaneStateChange(this, this.state.mode, this.previousState.mode);
        this.layout.animateElements();
    }

    hide() {
        this.layout.saveElementSizes();
        this.setState({showing: false});
        this.layout.onPaneStateChange(this, this.state.mode, this.previousState.mode);
        this.layout.animateElements();
    }


    render() {
        super.render();
        this.setPaneControls();
        const { backgroundColor } = this.shadow.querySelector('.content').style;
        this.style.backgroundColor = backgroundColor;
    }

    fixInnerSize(size) {
        super.fixInnerSize(size);
        const innerElem = this.shadow.querySelector('.pane');
        if (this.isSideways) {
            innerElem.style.width = size === undefined ? '100%' : size + 'px';
        } else {
            innerElem.style.height = size === undefined ? '100%' : size + 'px';
        }
    }

    static template(data) {
        return `
            <style>
            :host {
                width: ${data.width};
                height: ${data.height};
                padding: 0;
                margin: 0;
                box-sizing: border-box;
                overflow: hidden;
                flex-basis: ${data.basis};
                flex-grow: ${data.grow};
            }
            
            .pane {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                box-sizing: border-box;
            }

            .titleBarContainer {
                flex-direction: row;
            }

            .title {
                flex-grow: 1;
            }

            .minimise, .maximise, .restore {
                flex-grow: 0;
                cursor: pointer;
            }

            .minimise {
                display: ${data.canMinimise ? 'block' : 'none'};
            }
            
            .minimise::before {
                content: '×';
            }
            
            .maximise::before {
                content: '+';
            }
            
            .restore::before {
                content: '−';
            }

            .contentArea {
                flex-grow: 1;
                position: relative;
                overflow: hidden;
            }

            .content {
                position: ${data.initialBasis === 'content' ? 'static' : 'absolute'};
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                overflow: ${data.initialBasis === 'content' ? 'visible' : 'auto'};
            }

            </style>
            <div part="pane" class="pane">
                <div part="titleBarContainer" class="titleBarContainer" style="display: ${data.showTitleBar ? 'flex' : 'none'}">
                    <div part="titleBar title" class="titleBar title" style="${data.titleBarStyle}">${data.titleText}</div>
                    <div part="titleBar button restore" class="titleBar restore" style="${data.titleBarStyle}" title="restore pane"></div>
                    <div part="titleBar button maximise" class="titleBar maximise" style="${data.titleBarStyle}" title="maximise pane"></div>
                    <div part="titleBar button minimise" class="titleBar minimise" style="${data.titleBarStyle}" title="minimise pane"></div>
                </div>
                <div class="contentArea">
                    <div part="content" class="content" style="${data.contentStyle}">
                        <slot> </slot>
                    </div>
                </div>
            </div>
        `;
    }
}
