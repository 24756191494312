import {Model, MyassaysComponent, PropTypes} from 'myassays-global';
import Layout from './Layout';
import {directions} from './constants.js';

export default class LayoutElement extends MyassaysComponent {
    constructor() {
        super();

        this._layout = undefined;
        this.shadow = this.attachShadow({mode: 'open'});
    }

    static get propTypes() {
        return {[PropTypes.ALLOW_HYPHENS_IN_ATTRIBUTE_NAMES]: false};
    }

    get initialState() {
        // can make use of _props as initial attributes have been processed.
        return {
            showing: true,
        };
    }

    propertyChangedCallback(attrName, oldVal, newVal) {
        // e.g. update the DOM or _state from _props after a _props change.
    }

    stateChangedCallback(evt) {
        // e.g. update the DOM or _state from _state after a _state change.
    }

    static get observedAttributes() {
        return PropTypes.getObserved(this.propTypes);
    }

    connectedCallback() {
        this._props = PropTypes.attributesToProps(this);
        this._state = new Model(this.initialState);
        this.render();
        this._state.addEventListener('change', evt => this.stateChangedCallback(evt));
        this.attributesHaveBeenRead = true;
    }

    disconnectedCallback() {
        delete this._group;
        delete this._layout;
    }

    attributeChangedCallback(attrName, oldVal, newVal) {
        if (this.attributesHaveBeenRead) {
            const props = PropTypes.attributesToProps(this, attrName);
            const propName = Object.keys(props)[0];
            const previousValue = this._props[propName];
            const newValue = props[propName];
            this._props = {
                ...this._props,
                ...props,
            };
            this.propertyChangedCallback(propName, previousValue, newValue);
        }
    }

    applySkin(styles) {
        this.shadow.adoptedStyleSheets = [styles];
    }

    render() {
        this.shadow.innerHTML = this.constructor.template(this.templateData);
        //console.log(this.props.id, 'rendered', this.isSideways);
    }

    get templateData() {
        return {...this.props, ...this.state};
    }

    updateView() {
        //console.log(this.props.id, 'updated');
    }

    fixInnerSize(size) {
        // override to fix the inner content to the specified pixels.
        // Call without size to unfix.
    }

    setState(newAttributes, noTrigger = false) {
        this._state.set(newAttributes, noTrigger);
    }

    get state() {
        return this._state.attributes;
    }

    get previousState() {
        return this._state.previousAttributes;
    }

    hasChanged(attributeName) {
        return this.state[attributeName] !== this.previousState[attributeName];
    }

    get props() {
        return {
            ...this._props,
        };
    }

    get isVisible() {
        // override to return whether an element should be visible
        return true;
    }

    get group() {
        if (this._group) {
            return this._group;
        } else {
            const layoutElement = this.layout;
            const groupElement = this.parentElement;
            return this._group = (groupElement !== layoutElement ? groupElement : null);
        }
    }

    get isSideways() {
        return this.group ? (this.group.props ? this.group.props.direction === directions.HORIZONTAL : undefined) : undefined;
    }

    get layout() {
        if (this._layout) {
            return this._layout;
        } else {
            let layout = this;
            while (! (layout instanceof Layout)) {
                layout = layout.parentElement;
            }
            return this._layout = layout;
        }
    }
}
