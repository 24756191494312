
import LayoutElement from './LayoutElement';
import PropTypes from 'myassays-global/PropTypes';
import { directions } from './constants';
import {Utils} from 'myassays-global';

export default class Splitter extends LayoutElement {
    static get propTypes() {
        return {
            ...super.propTypes,
            style: PropTypes.style.default(''),
            class: PropTypes.string.default(''),
            resizes: PropTypes.string.required,
        }
    }

    constructor() {
        super();

        Utils.makeDraggable(this)
            .onStart(this._dragStart)
            .onMove(this._dragMove)
            .onEnd(this._dragEnd)
            .onException(this._dragEnd)
    }

    _dragStart = (evt, positionInfo) => {
        evt.preventDefault();
        const group = this.group;
        const resized = this.resizedMember;
        const units = resized.props.initialBasis ? resized.props.initialBasis.replace(/^.*?(px|%)$/, '$1') : '%';
        const isHorizontal = group.props.direction === directions.HORIZONTAL;
        const spaceAvailable = isHorizontal ? group.offsetWidth : group.offsetHeight;
        const size = isHorizontal ? resized.offsetWidth : resized.offsetHeight;
        const initialSize = units === 'px' ? size : 100 * size / spaceAvailable;
        this.mouseDownData = {
            resized: resized,
            downPos: {x: positionInfo.pageX, y: positionInfo.pageY},
            units: units,
            isHorizontal: isHorizontal,
            spaceAvailable: spaceAvailable,
            initialSize: initialSize,
        };
        this.layout.getLayoutElementsByType(LayoutElement).forEach(element => {
            element.setState({isResizing: true});
        });
    }

    _dragMove = (evt, positionInfo) => {
        evt.preventDefault();
        const pos = {x: positionInfo.pageX, y: positionInfo.pageY};
        const { resized, downPos, units, isHorizontal, spaceAvailable, initialSize } = this.mouseDownData;
        const multiplier = (resized === this.nextElementSibling) ? -1 : 1;
        if (units === 'px') {
            if (isHorizontal) {
                resized.setState({size: `${initialSize + multiplier * (pos.x - downPos.x)}px`});
            } else {
                resized.setState({size: `${initialSize + multiplier * (pos.y - downPos.y)}px`});
            }
        } else {
            if (isHorizontal) {
                resized.setState({size: `${initialSize + 100 * multiplier * (pos.x - downPos.x)/spaceAvailable}%`});
            } else {
                resized.setState({size: `${initialSize + 100 * multiplier * (pos.y - downPos.y)/spaceAvailable}%`});
            }
        }
    }

    _dragEnd = evt => {
        evt.preventDefault();
        const { resized, units, isHorizontal, spaceAvailable } = this.mouseDownData;
        const size = isHorizontal ? resized.offsetWidth : resized.offsetHeight;
        resized.setState({
            size: units === 'px' ? `${size}px` : `${100 * size / spaceAvailable}%`,
        });
        this.layout.getLayoutElementsByType(LayoutElement).forEach(element => {
            element.setState({
                isResizing: false,
            });
        });
        delete this.mouseDownData;
    }

    get resizedMember() {
        return this.parentElement.querySelector('#' + this._props.resizes);
    }

    stateChangedCallback(evt) {
        super.stateChangedCallback(evt);
        const { hasChanged } = evt.data;

        hasChanged('showing', truth => {
            this.updateView();
        });
    }

    get isVisible() {
        return this._state.get('showing');
    }

    updateView() {
        this.style.flexBasis = this.isVisible ? 'content' : '0';
    }

    get templateData() {
        return {
            ...super.templateData,
            sideways: this.isSideways,
        }
    }

    static template(data) {
        return `
            <style>
            :host {
                box-sizing: border-box;
                flex-basis: content;
                overflow: hidden;
            }
            
            .splitter {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                min-width: 6px;
                min-height: 6px;
                cursor: ${data.sideways ? 'col-resize' : 'row-resize'};
            }
            </style>
            <div part="splitter" class="splitter"> </div>
        `;
    }
}
