
import LayoutElement from './LayoutElement.js';
import Group from './Group';
import Pane from './Pane';
import Splitter from './Splitter';
import MinimisedPaneList from './MinimisedPaneList';
import PropTypes from 'myassays-global/PropTypes';
import { modes } from './constants';
import Animator from './Animator.js';

export default class Layout extends LayoutElement {
    static get propTypes() {
        return {
            ...super.propTypes,
            id: PropTypes.string.required,
            style: PropTypes.style.default(''),
            class: PropTypes.string.default(''),
            skin: PropTypes.string,
            transitionMilliseconds: PropTypes.number.default(300),
        }
    }

    static template(props) {
        return `
            <style>
            :host {
                display: block;
                visibility: hidden;
                box-sizing: border-box;
                border-style: solid;
                border-color: black;
                border-width: 0 0 1px 1px;
            }
            </style>
            <slot> </slot>
        `;
    }

    constructor() {
        super();
        if (document.readyState === "loaded" || document.readyState === "complete") {
            this._domContentLoaded = true;
        } else {
            document.addEventListener('DOMContentLoaded', () => {
                this._onDomContentLoaded();
            });
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this._isInvalid = undefined;
        if (this.props.skin) {
            this._fetchingSkin = true;
            const skinElement = document.getElementById(this.props.skin);
            if (skinElement instanceof HTMLStyleElement) {
                const onSkinLoad = () => {
                    this._fetchingSkin = false;
                    this._skinStyles = new CSSStyleSheet();
                    this._skinStyles.replaceSync(skinElement.innerHTML);
                    if (this._domContentLoaded) {
                        this.initialRender();
                    }
                    skinElement.removeEventListener('load', onSkinLoad);
                }
                skinElement.addEventListener('load', onSkinLoad);
            } else {
                fetch(this.props.skin).then(response => {
                    this._fetchingSkin = false;
                    response.text().then(text => {
                        this._skinStyles = new CSSStyleSheet();
                        this._skinStyles.replaceSync(text);
                        if (this._domContentLoaded) {
                            this.initialRender();
                        }
                    });
                }, error => {
                    this._fetchingSkin = false;
                    if (this._domContentLoaded) {
                        this.initialRender();
                    }
                });
            }
        } else if (this._domContentLoaded) {
            setTimeout(() => this.initialRender(), 1);
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    _onDomContentLoaded() {
        this._domContentLoaded = true;
        if (!this._fetchingSkin) {
            setTimeout(() => this.initialRender(), 1);
        }
    }

    initialRender() {
        if (this.skinStyles) {
            const elements = this.getAllLayoutElements();
            elements.forEach(element => element.applySkin(this.skinStyles));
        }
        this.getLayoutElementsByType(Pane).forEach(pane => {
            pane.updateView();
            pane.group.onMemberVisibilityChange(pane);
            this.onPaneStateChange(pane, pane.state.mode, pane.previousState.mode);
        });
        this.style.visibility = 'visible';
    }

    getAllLayoutElements() {
        const selector = [Group, Pane, Splitter, MinimisedPaneList].map(Type => customElements.getName(Type)).join(', ');
        return Array.from(this.querySelectorAll(selector));
    }

    getLayoutElementById(id) {
        return this.querySelector(`#${id}`);
    }

    getLayoutElementsByType(Type) {
        const tagName = customElements.getName(Type);
        return Array.from(this.querySelectorAll(tagName));
    }

    getMaximisedPaneElement() {
        const element = this.getLayoutElementsByType(Pane).find(pane => pane.state && pane.state.mode === modes.MAXIMISED);
        return element || undefined;
    }

    getMinimisedPaneListElement() {
        const element = this.getLayoutElementsByType(MinimisedPaneList)[0];
        return element || undefined;
    }

    get skinStyles() {
        return this._skinStyles;
    }

    saveElementSizes() {
        if (this.props.transitionMilliseconds === 0) {
            return;
        }

        this.getAllLayoutElements().forEach(elem => {
            const isSideways = elem.isSideways;
            elem._saveData = {
                basis: elem.style.flexBasis,
                grow: elem.style.flexGrow,
                size: isSideways ? elem.offsetWidth : elem.offsetHeight,
                minSize: isSideways ? elem.style.minWidth : elem.style.minHeight,
            }
        });
    }

    animateElements() {
        if (this.props.transitionMilliseconds === 0) {
            return;
        }

        this._animator && this._animator.cancel();
        this._allChangedElements = this.getAllLayoutElements().filter(elem => elem.style.flexBasis !== elem._saveData.basis);

        this._allChangedElements.forEach(elem => {
            const isSideways = elem.isSideways;
            elem._newData = {
                basis: elem.style.flexBasis,
                grow: elem.style.flexGrow,
                size: isSideways ? elem.offsetWidth : elem.offsetHeight,
                minSize: isSideways ? elem.style.minWidth : elem.style.minHeight,
            }
        });

        const endAndCancelCallBack = animator => {
            this._allChangedElements.forEach(elem => {
                elem.style.transition = undefined;
                if (elem._newData) {
                    elem.style.flexBasis = elem._newData.basis;
                    elem.style.flexGrow = elem._newData.grow;
                    if (elem.isSideways) {
                        elem.style.minWidth = elem._newData.minSize;
                    } else {
                        elem.style.minHeight = elem._newData.minSize;
                    }
                }
                elem.fixInnerSize();
            });
            this.style.pointerEvents = 'all';
            delete this._allChangedElements;
        };

        this._animator || (this._animator = Animator
            .create(this.props.transitionMilliseconds)
            .onInitialise(animator => {
                this.style.pointerEvents = 'none';
                this._allChangedElements.forEach(elem => {
                    const isSideways = elem.isSideways;
                    const group = elem.group;
                    elem.style.flexGrow = '0';
                    if (group && this._allChangedElements.includes(group) && group._newData.size === 0) {
                        elem.style.flexBasis = elem._saveData.basis;
                        elem.style.flexGrow = elem._saveData.grow;
                        if (elem.isSideways) {
                            elem.style.minWidth = elem._saveData.minSize;
                        } else {
                            elem.style.minHeight = elem._saveData.minSize;
                        }
                    } else if (!group || !(this._allChangedElements.includes(group) && group._saveData.size === 0)) {
                        elem.style.flexBasis = elem._saveData.size + 'px';
                        if (isSideways) {
                            elem.style.minWidth = '0';
                        } else {
                            elem.style.minHeight = '0';
                        }
                        if (elem._saveData.size === 0) {
                            elem.fixInnerSize(elem._newData.size);
                        } else if (elem._newData.size === 0) {
                            elem.fixInnerSize(elem._saveData.size);
                        }
                    }
                });
            })
            .onStart(animator => {
                this._allChangedElements.forEach(elem => {
                    const group = elem.group;
                    if (!group || !(this._allChangedElements.includes(group) && group._newData.size === 0)) {
                        elem.style.transition = `flex-basis ${this.props.transitionMilliseconds}ms`;
                        elem.style.flexBasis = elem._newData.size + 'px';
                    }
                });
            })
            .onEnd(animator => {
                endAndCancelCallBack();
            })
            .onCancel(animator => {
                endAndCancelCallBack();
            })
            .onIdle(animator => {
                this.getAllLayoutElements().forEach(elem => {
                    delete elem._saveData;
                    delete elem._newData;
                });
            }));
        this._animator.begin();
    }

    onPaneStateChange(pane, mode, previousMode) {
        const panes = this.getLayoutElementsByType(Pane).filter(element => element.props && element.props.canMinimise);
        panes.forEach(element => {
            if (element !== pane) {
                if (mode === modes.MAXIMISED && previousMode === modes.NORMAL) {
                    element.setState({forceMinimised: true});
                } else if (mode !== modes.MAXIMISED && previousMode === modes.MAXIMISED) {
                    element.setState({forceMinimised: false});
                } else if (mode === modes.NORMAL && previousMode === modes.MINIMISED) {
                    if (element.state.forceMinimised) {
                        element.setState({
                            forceMinimised: false,
                            mode: modes.MINIMISED,
                        });
                    }
                }
            }
        });
        const minimisedPaneList = this.getMinimisedPaneListElement();
        minimisedPaneList && minimisedPaneList.setState && minimisedPaneList.setState({paneList: panes.map(element => {
            const {props, state} = element;
            return {
                id: props.id,
                included: !element.isVisible && state.showing,
                titleText: props.minimisedTitle || props.titleText,
                style: props.titleBarStyle,
            }
        })});
    }

    render() {
        if (!this._fetchingSkin) {
            super.render();
        }
    }

    minimisedTabClicked(paneId) {
        // A tab mey exist because the pane it represents is mode MINIMISED,
        // or because another pane is mode MAXIMISED, in which case this pane
        // is either mode NORMAL or MINIMISED if it had been minimised before.
        this.saveElementSizes();

        const pane = this.getLayoutElementById(paneId);
        if (pane) {
            const maximisedPane = this.getMaximisedPaneElement();
            if (maximisedPane) {
                this.getLayoutElementsByType(Pane).forEach(paneElem => {
                    if (paneElem === pane) {
                        paneElem.setState({
                            mode: modes.NORMAL,
                            forceMinimised: false,
                        });
                    } else if (paneElem.state.forceMinimised) {
                        paneElem.setState({
                            mode: modes.MINIMISED,
                            forceMinimised: false,
                        });
                    }
                });
                maximisedPane.setState({mode: modes.NORMAL})
            } else {
                pane.setState({mode: modes.NORMAL});
            }
        }

        this.animateElements();
    }

    getPaneById(id) {
        const pane = this.getLayoutElementById(id);
        if (!pane) {
            throw new Error(`mp-pane#${id} does not exist in mp-layout#${this.id}`);
        } else {
            return pane;
        }
    }
}
