
import LayoutElement from './LayoutElement';
import PropTypes from 'myassays-global/PropTypes';
import { directions } from './constants';

export default class MinimisedPaneList extends LayoutElement {
    static get propTypes() {
        return {
            ...super.propTypes,
            id: PropTypes.string,
            style: PropTypes.style.default(''),
            class: PropTypes.string.default(''),
            tabStyle: PropTypes.style.default(''),
        }
    }

    get initialState() {
        return {
            ...super.initialState,
            paneList: [],
        };
    }

    stateChangedCallback(evt) {
        super.stateChangedCallback(evt);
        const { hasChanged, attributes } = evt.data;
        if (hasChanged('showing') || hasChanged('paneList')) {
            this.updateView()
        }

        hasChanged('paneList', (list, previousList) => {
            /*
            A tab is created for every pane that can be minimised
             */
            const minimisedPaneList = this.shadow.querySelector('.minimisedPaneList');
            if (!minimisedPaneList) return;

            let tabIdsToShow = list.filter(item => item.included).map(item => item.id);
            if (tabIdsToShow.length === 0) {
                tabIdsToShow = previousList.filter(item => item.included).map(item => item.id);
            }

            previousList && previousList.forEach(previousItem => {
                if (! list.find(item => item.id === previousItem.id)) {
                    const elem = minimisedPaneList.querySelector(`[data-id="${previousItem.id}"]`);
                    elem && elem.remove();
                }
            });
            list.forEach(item => {
                if (! previousList.find(previousItem => previousItem.id === item.id)) {
                    minimisedPaneList.innerHTML += this.constructor.tabTemplate({
                        ...item,
                        orientation: this.isSideways ? 'sideways' : 'normal',
                        tabStyle: this._props.tabStyle,
                    });
                } else {
                    const elem = minimisedPaneList.querySelector(`[data-id="${item.id}"]`);
                    elem.style.display = tabIdsToShow.includes(item.id) ? 'block' : 'none';
                }
            });
        });
    }

    get isVisible() {
        const { showing, paneList } = this.state;
        return showing && paneList.filter(item => item.included).length > 0;
    }

    updateView() {
        this.style.flexBasis = this.isVisible ? 'content' : '0';
    }

    constructor() {
        super();
        this.shadow.addEventListener('click', this.onClick);
    }

    onClick = evt => {
        switch (evt.target.className) {
            case 'tab':
                const paneId = evt.target.getAttribute('data-id');
                this.layout.minimisedTabClicked(paneId);
                break;
        }
        evt.preventDefault();
    }

    get templateData() {
        return {
            ...super.templateData,
            sideways: this.isSideways,
        }
    }

    static template(data) {
        return `
            <style>
            :host {
                box-sizing: border-box;
                flex-basis: content;
                overflow: hidden;
            }
            .minimisedPaneList {
                display: flex;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                flex-direction: ${data.sideways ? 'column' : 'row'};
            }
            .tab {
                flex-grow: 1;
                cursor: pointer;
                box-sizing: border-box;
                padding: ${data.sideways ? '6px 3px' : '3px 6px'};
                writing-mode: ${data.sideways ? 'vertical-rl' : 'horizontal-tb'};
                text-orientation: ${data.sideways ? 'sideways' : 'upright'};
                user-select: none;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            </style>
            <div part="minimisedPaneList" class="minimisedPaneList">
                
            </div>
        `;
    }

    static tabTemplate = data => {
        return `
        <div part="tab ${data.id} ${data.orientation}" class="tab" data-id="${data.id}" style="${data.tabStyle};${data.style};display: ${data.included ? 'block' : 'none'}" title="restore pane">${data.titleText}</div>
        `;
    }

}
