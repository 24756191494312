
import { directions } from './constants';
import PropTypes from 'myassays-global/PropTypes';
import ResizableElement from './ResizableElement.js';
import Splitter from './Splitter.js';

export default class Group extends ResizableElement {
    static get propTypes() {
        return {
            ...super.propTypes,
            style: PropTypes.style.default(''),
            class: PropTypes.string.default(''),
            direction: PropTypes.string.lookup(directions).required,
        }
    }

    onMemberVisibilityChange(member) {
        const children = Array.from(this.children);
        children.forEach((item, index, array) => {
            if (item instanceof Splitter) {
                let showing = false;
                const { resizedMember } = item;
                if (resizedMember.isVisible) {
                    // check forwards and backwards for visible GroupMember instances
                    for (let i = index - 1; i >= 0; i--) {
                        const sibling = array[i];
                        if (sibling instanceof ResizableElement && sibling.isVisible) {
                            showing = true;
                            break;
                        }
                    }
                    if (showing) {
                        showing = false;
                        let precedingSplitter = undefined;
                        for (let i = index + 1; i < array.length; i++) {
                            const sibling = array[i];
                            if (sibling instanceof ResizableElement && sibling.isVisible) {
                                showing = precedingSplitter ? precedingSplitter.resizedMember !== sibling : true;
                                break;
                            }
                            precedingSplitter = sibling instanceof Splitter ? sibling : undefined;
                        }
                    }
                }
                item.setState({showing});
                resizedMember.setState({forceFluid: !showing});
            }
        });
        const group = this.group;
        this.updateView();
        group && group.onMemberVisibilityChange(this);
    }

    get isVisible() {
        const children = Array.from(this.children);
        return children.filter(item => item.state && item.isVisible).length > 0;
    }

    fixInnerSize(size) {
        super.fixInnerSize(size);
        const innerElem = this.shadow.querySelector('.group');
        if (this.isSideways) {
            innerElem.style.width = size === undefined ? '100%' : size + 'px';
        } else {
            innerElem.style.height = size === undefined ? '100%' : size + 'px';
        }
    }

    static template(data) {
        return `
            <style>
            :host {
                width: ${data.width};
                height: ${data.height};
                box-sizing: border-box;
                overflow: hidden;
                flex-basis: ${data.basis};
                flex-grow: ${data.grow};
            }
            .group {
                display: flex;
                width: 100%;
                height: 100%;
                flex-direction: ${data.direction === directions.HORIZONTAL ? 'row' : 'column'};
                box-sizing: border-box;
            }            
            </style>

            <div class="group">
                <slot> </slot>
            </div>
        `;
    }
}
