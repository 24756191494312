import {nodeDetachedFromDocument} from 'jsdom/lib/jsdom/living/named-properties-window.js';

export default class Utils {
    static repeat (times, iterator) {
        return Array(times).fill('').map((val, index) => iterator(index)).join('');
    }

    static setCssVariable(css, name, value) {
        const cssString = String(css);
        if (cssString.indexOf(`${name}:`) > -1) {
            return cssString.replace(new RegExp(`${name}:.*?(;|})`), `${name}: ${value}$1`);
        } else {
            return `* {${name}: ${value};} ${cssString}`;
        }
    }

    static classes(...list) {
        let classNames = [];
        let className;
        while (className = list.shift()) {
            if (!!list.shift()) classNames.push(className);
        }
        return classNames.join(' ');
    }

    static range(start, stop, step) {
        if (stop === null) {
            stop = start || 0;
            start = 0;
        }
        if (!step) {
            step = stop < start ? -1 : 1;
        }

        const length = Math.max(Math.ceil((stop - start) / step), 0);
        const range = Array(length);

        for (let idx = 0; idx < length; idx++, start += step) {
            range[idx] = start;
        }

        return range;
    }

    static isBetween (num1, num2, num3) {
        if (num2 > num3) {
            return num1 <= num2 && num1 >= num3;
        } else {
            return num1 >= num2 && num1 <= num3;
        }
    }

    static makeDataUrl(data) {
        return `data:image/svg+xml,${encodeURIComponent(data)}`;
    }

    static xmlToObject(xmlString, options = {}) {
        const { arrays = [], exclude = [], booleans = [], numbers = [], preserveCapitals = false } = options;

        function formatName(name) {
            return preserveCapitals ? name : name.charAt(0).toLowerCase() + name.slice(1);
        }

        function convertType(name, value) {
            if (booleans.includes(name)) {
                return value.toLowerCase() === 'true';
            }
            if (numbers.includes(name)) {
                return Number(value);
            }
            return value;
        }

        const parser = new DOMParser();
        let dom;
        try {
            dom = parser.parseFromString(xmlString, 'application/xml');
        } catch (error) {
            console.log('ERROR: XmlToObject failed to parse XML');
            return null;
        }

        function parseNode(xmlNode, result) {
            let nodeName = xmlNode.nodeName;
            if (nodeName === "#text") {
                let v = xmlNode.nodeValue;
                if (v.trim()) result['#text'] = v;
                return;
            }

            if (exclude.indexOf(nodeName) !== -1) {
                return;
            }

            name = formatName(nodeName);

            let jsonNode = {},
                existing = result[name];
            if (existing) {
                if (!Array.isArray(existing)) result[name] = [existing, jsonNode];
                else result[name].push(jsonNode);
            }
            else {
                if (arrays.indexOf(nodeName) !== -1) result[name] = [jsonNode];
                else result[name] = jsonNode;
            }

            if (xmlNode.attributes) for (let attribute of xmlNode.attributes) jsonNode[formatName(attribute.nodeName)] = convertType(attribute.nodeName, attribute.nodeValue);

            for (let node of xmlNode.childNodes) parseNode(node, jsonNode);
        }

        let result = {};
        for (let node of dom.childNodes) parseNode(node, result);

        return result;
    }

    static doAndResizeSmoothly(element, action, endAnimationCallback = () => {}) {
        const startWidth = element.offsetWidth;
        const startHeight = element.offsetHeight;
        action();
        const childElement = element.firstElementChild;
        const childRect = childElement.getBoundingClientRect();
        const endWidth = element.offsetWidth;
        const endHeight = element.offsetHeight;
        const styleWidth = element.style.width;
        const styleHeight = element.style.height;
        const childStyleWidth = childElement.style.width;
        const childStyleHeight = childElement.style.height;
        const styleOverflow = element.style.overflow;
        element.style.overflow = 'hidden';
        element.style.width = startWidth + 'px';
        element.style.height = startHeight + 'px';
        element.style.transition = 'width 200ms, height 200ms';
        childElement.style.width = childRect.width + 'px';
        childElement.style.height = childRect.height + 'px';
        setTimeout(() => {
            element.style.width = endWidth + 'px';
            element.style.height = endHeight + 'px';
        });
        setTimeout(() => {
            element.style.width = styleWidth;
            element.style.height = styleHeight;
            element.style.overflow = styleOverflow;
            element.style.transition = 'none';
            childElement.style.width = childStyleWidth;
            childElement.style.height = childStyleHeight;
            endAnimationCallback();
        }, 201);
    }

    static makeDraggable(element, referenceElement = document.body) {
        class PositionInfo {
            constructor(dataObject) {
                this.pageX = dataObject.pageX;
                this.pageY = dataObject.pageY;
                this.screenX = dataObject.pageX;
                this.screenY = dataObject.pageY;
                this.clientX = dataObject.clientX;
                this.clientY = dataObject.clientY;
                this.target = dataObject.target;
            }
        }

        class dragController {
            constructor(element, referenceElement) {
                this._element = element;
                this._referenceElement = referenceElement;
                this._element.addEventListener('mousedown', this._onMouseDown);
                this._element.addEventListener('touchstart', this._onTouchStart, {passive: false});
            }

            _onMouseDown = (e) => {
                if (this._startHandler && this._startHandler(e, new PositionInfo(e), this) !== false) {
                    this._referenceElement.addEventListener('mousemove', this._onMouseMove);
                    this._referenceElement.addEventListener('mouseup', this._onMouseUp);
                    this._referenceElement.addEventListener('mouseleave', this._onMouseLeave);
                }
            }

            _onMouseMove = (e) => {
                this._moveHandler && this._moveHandler(e, new PositionInfo(e), this);
            }

            _onMouseUp = (e) => {
                this._removeMouseEvents();
                this._endHandler && this._endHandler(e, this);
            }

            _onMouseLeave = (e) => {
                this._removeMouseEvents();
                this._exceptionHandler && this._exceptionHandler(e, this);
            }

            _removeMouseEvents() {
                this._referenceElement.removeEventListener('mousemove', this._onMouseMove);
                this._referenceElement.removeEventListener('mouseup', this._onMouseUp);
                this._referenceElement.removeEventListener('mouseleave', this._onMouseLeave);
            }


            _onTouchStart = (e) => {
                if (this._startHandler && this._startHandler(e, new PositionInfo(e.touches[0]), this) !== false) {
                    this._referenceElement.addEventListener('touchmove', this._onTouchMove, {passive: false});
                    this._referenceElement.addEventListener('touchend', this._onTouchEnd);
                    this._referenceElement.addEventListener('touchcancel', this._onTouchCancel);
                }
            }

            _onTouchMove = (e) => {
                this._moveHandler && this._moveHandler(e, new PositionInfo(e.touches[0]), this);
            }

            _onTouchEnd = (e) => {
                this._removeTouchEvents();
                this._endHandler && this._endHandler(e, this);
            }

            _onTouchCancel = (e) => {
                this._removeTouchEvents();
                this._exceptionHandler && this._exceptionHandler(e, this);
            }

            _removeTouchEvents() {
                this._referenceElement.removeEventListener('touchmove', this._onTouchMove);
                this._referenceElement.removeEventListener('touchend', this._onTouchEnd);
                this._referenceElement.removeEventListener('touchcancel', this._onTouchCancel);
            }

            onStart(startHandler = (event, positionInfo, controller) => {return this}) {
                this._startHandler = startHandler;
                return this;
            }

            onMove(moveHandler = (event, positionInfo, controller) => {return this}) {
                this._moveHandler = moveHandler;
                return this;
            }

            onEnd(endHandler = (event, controller) => {return this}) {
                this._endHandler = endHandler;
                return this;
            }

            onException(exceptionHandler = (event, controller) => {return this}) {
                this._exceptionHandler = exceptionHandler;
                return this;
            }

            stopListening() {
                this._element.removeEventListener('mousedown', this._onMouseDown);
                this._element.removeEventListener('touchstart', this._onTouchStart);
                delete this._element;
                delete this._referenceElement;
            }
        }

        return new dragController(element, referenceElement);
    }

    static makeMovable(popupElement, dragHandleElement) {
        let startMousePos, startPopupPos;
        const handlers = {};
        this.makeDraggable(dragHandleElement)
            .onStart((evt, positionInfo) => {
                if (positionInfo.target === dragHandleElement) {
                    evt.preventDefault();
                    popupElement.style.top = popupElement.offsetTop + 'px';
                    popupElement.style.left = popupElement.offsetLeft + 'px';
                    popupElement.style.position = 'absolute';
                    startMousePos = {
                        x: positionInfo.pageX,
                        y: positionInfo.pageY,
                    };
                    startPopupPos = {
                        x: popupElement.offsetLeft,
                        y: popupElement.offsetTop,
                    };
                    if (handlers.startHandler) {
                        handlers.startHandler(evt, positionInfo);
                    }
                    return true;
                } else {
                    return false;
                }
            })
            .onMove((evt, positionInfo) => {
                evt.preventDefault();
                const currentMousePos = {
                    x: positionInfo.pageX,
                    y: positionInfo.pageY,
                };
                const delta = {
                    x: currentMousePos.x - startMousePos.x,
                    y: currentMousePos.y - startMousePos.y,
                };
                popupElement.style.left = (startPopupPos.x + delta.x) + 'px';
                popupElement.style.top = (startPopupPos.y + delta.y) + 'px';
                if (handlers.moveHandler) {
                    handlers.moveHandler(evt, positionInfo);
                }
            });
        return {
            onStart: handler => handlers.startHandler = handler,
            onMove: handler => handlers.moveHandler = handler,
        }
    }
}
