import { PropTypes } from 'myassays-global';

export default class MyassaysComponent extends HTMLElement {
    static get documentationMarkdown() {
        const { propTypes } = this;
        if (propTypes) {
            return `\
${PropTypes.propTypesToDoc(propTypes)}`;
        } else {
            return 'No documentation available';
        }
    }
}
